import React from "react";

export default function Home()
{
    return(
        <>
 <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-interval="false">
  <div class="carousel-indicators carousel-indicators-numbers">
    
    <div id="rightSLideBar" className="hidden-xs hidden-sm">
        <ul className="colorfff ultagliststyle" >
    
          <li className="pb-10 fw-bold"> <span  className="numbers  active" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0">01</span></li>
          <li className="pb-10 fw-bold"> <span  className="numbers" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" >02</span></li>
         
          <li className="pb-10 fw-bold"> <span className="numbers" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" >03</span></li>
         
          <li className="pb-10 fw-bold"> <span  className="numbers" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" >04</span></li>
          
          <li className="pb-10 fw-bold"> <span  className="numbers" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" >05</span></li>
          
          <li className="pb-10 fw-bold"> <span  className="numbers"  data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" >06</span></li>
        </ul> 
    </div>
    
  </div>
  <a href="https://urbanfitstore.com"  className="shopnowbtn fixtab"  target="blank" > SHOP NOW <i className="fa fa-angle-right righticon" ></i></a>
   
  <span  className="footerurlsect">Urbanfit Wellness Pvt Ltd © 2024 All Rights Reserved <br/>
  Design and developed by <a className="footerurl" href="https://www.nurasoftech.com/Best_Web_Development_Company_in_Pune.php" target="_blank"  >Nura Softech Pvt Ltd.</a></span>
 
  <div class="carousel-inner">
    <div class="carousel-item active">
    <section className="hero   fullheigh section1" >
    <div className="maintxt">
          <img src="assets/img/flex/01.png" className="img-responsive desktopview " />
          <img src="assets/img/flex/m01.png" className="img-responsive mobileview " />
          <div className="d-flex align-items-center justify-content-center subsection">
             <img src="assets/img/flex/Flex it.png" className="textimg"/>
            </div>
            <div className="textsection">
                   <span className="bannertext">WORN BY <br /> <span className="textunderline">ALL</span></span><br /><br />
                 
            </div>
      </div>
   </section>
    </div>
    <div class="carousel-item">
    <section className="hero   fullheigh section2 "  >
      <div className="maintxt">
          <img src="assets/img/flex/02.png" className="img-responsive desktopview imgcls" />
           <img src="assets/img/flex/m02.png" className="img-responsive mobileview imgcls" />
          <div className="d-flex align-items-center justify-content-center subsection">
             <img src="assets/img/flex/Sportx.png" className="textimg"/>
            </div>
            <div className="textsection">
                   <span className="bannertext">NO JOKE<br /> <span className="textunderline">JUST PROS.</span></span><br /><br />
                   
            </div>
      </div>
    
  </section>
    </div>
    <div class="carousel-item">
    <section className="hero   fullheigh section3"  >
   <div className="maintxt">
          <img src="assets/img/flex/03.png" className="img-responsive desktopview imgcls" />
           <img src="assets/img/flex/m03.png" className="img-responsive mobileview imgcls" />
           <div className="d-flex align-items-center justify-content-center subsection">
             <img src="assets/img/flex/FITX.png" className="textimg"/>
            </div>
             <div className="textsection">
                   <span className="bannertext">READY?<br /> <span className="textunderline">CONQUER.</span></span><br /><br />
                   
            </div>
      </div>
  </section>
    </div>
    <div class="carousel-item">
    <section className="hero   fullheigh section4" id="img_default" >
     <div className="maintxt">
          <img src="assets/img/flex/04.png" className="img-responsive desktopview" />
          <img src="assets/img/flex/m04.png" className="img-responsive mobileview" />
          <div className="d-flex align-items-center justify-content-center subsection">
             <img src="assets/img/flex/Aerobix.png" className="textimg"/>
            </div>
            <div className="textsection">
                   <span className="bannertext">SUCCESS IS<br /> <span className="textunderline">AT HAND</span></span><br /><br />
          </div>         
      </div>
  </section>
    </div>
    <div class="carousel-item">
    <section className="hero   fullheigh section5" id="img_default" >
   <div className="maintxt">
          <img src="assets/img/flex/05.png" className="img-responsive desktopview" />
          <img src="assets/img/flex/m05.png" className="img-responsive mobileview" />
           <div className="d-flex align-items-center justify-content-center subsection">
     <img src="assets/img/flex/GYMX.png" className="textimg"/>
    </div>
     <div className="textsection">
           <span className="bannertext">LIFT THE<br /> <span className="textunderline">WEIGHTS</span></span><br /><br />
          
       </div>
      </div>
  </section>
    </div>
    <div class="carousel-item">
    <section className="hero   fullheigh section6" id="img_default" >
   
    
   <div className="maintxt">
         <img src="assets/img/flex/06.png" className="img-responsive desktopview" />
         <img src="assets/img/flex/m06.png" className="img-responsive mobileview" />
       <div className="d-flex align-items-center justify-content-center subsection">
    <img src="assets/img/flex/Flex it.png" className="textimg"/>
   </div>
   <div className="textsection">
          <span className="bannertext">LIFT THE<br /> <span className="textunderline">WEIGHTS</span></span><br /><br />
          
   </div>
     </div>
 </section>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

        </>
    )
}