import ReactDOM from "react-dom/client";
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './components/Home';
import Menubar from "./components/Menubar";
import Ourstory from "./components/Ourstory";
import Faq from "./components/Faq";
import Contactus from "./components/Contactus";

function App() {
  return (
    
    <BrowserRouter>
    
    <Routes>
   
      <Route path="/" element={<Menubar />}>
        <Route index element={<Home />} />
        <Route path="contactus" element={<Contactus />} />
        <Route path="ourstory" element={<Ourstory />} />
        <Route path="faq" element={<Faq />} />
       
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
