import React from "react";
import { Outlet, Link } from "react-router-dom";
export default function Menubar()
{
    return(
        <>
            <header id="header" className="fixed-top header-transparent">
                <div className="container-fluid d-flex align-items-center justify-content-between">

                <h1  className="logo"><a href=""><img src="assets/img/flex/Flex-White-logo.png" className="headerlogo" /></a></h1>
                
                <a href="index.html" className="logo"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a>

                <nav id="navbar" className="navbar">
                    
                <img src="assets/img/flex/toggleicon.png" className="toggleimage" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{cursor: 'pointer'}} />
                </nav>

                </div>
            </header>

      <Outlet />

      {/* <!--model start here --> */}

            <div className="modal  fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{width: '100%'}}>
            <div className="modal-dialog  modal-sm">
                <div className="modal-content">
                <div className="modal-header" style={{borderBottom: 'none'}}>
                
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{fontSize: '20px'}}></button>
                </div>
                <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        
                            
                        <div className="col-md-12">
                            <div className="koh-tab-content">
                            <div className="koh-tab-content-body">
                                <div className="koh-faq">
                                <div className="koh-faq-question ">
                                <span className="koh-faq-question-span litagmainlable spanlable animate slide-left delay-1"> Home </span> <i className="fa fa-chevron-right animate slide-left delay-1" aria-hidden="true" style={{fontSize: '25px',marginLeft: '10px'}}></i>
                                </div>
                                <div className="koh-faq-answer litagsublable">
                                    <a href="" className="color000 spanlable">Main Home</a><br />   
                                    <a href="" className="color000 spanlable">Winter Sport Store</a>  <br />   
                                    <a href="" className="color000 spanlable">Landing</a>   
                                </div>
                                </div>
                                <div className="koh-faq">
                                <div className="koh-faq-question litagmainlable">
                                <span className="koh-faq-question-span spanlable animate slide-left delay-12"> Our Story </span><i className="fa fa-chevron-right animate slide-left delay-12" aria-hidden="true" style={{fontSize: '25px',marginLeft: '10px'}}></i>
                                </div>
                                <div className="koh-faq-answer litagsublable">
                                    <Link to="/ourstory" className="color000 spanlable">Our Story</Link>
                                </div>
                                </div>
                                <div className="koh-faq">
                                <div className="koh-faq-question litagmainlable">
                                <span className="koh-faq-question-span spanlable animate slide-left delay-13"> FAQs </span><i className="fa fa-chevron-right animate slide-left delay-13" aria-hidden="true" style={{fontSize: '25px',marginLeft: '10px'}}></i>
                                </div>
                                <div className="koh-faq-answer litagsublable">
                                <Link to="/faq" className="color000 spanlable">FAQ's</Link>
                                </div>
                                </div>
                                <div className="koh-faq">
                                <div className="koh-faq-question litagmainlable">
                                <span className="koh-faq-question-span spanlable animate slide-left delay-14">  Contact us </span><i className="fa fa-chevron-right animate slide-left delay-14" aria-hidden="true" style={{fontSize: '25px',marginLeft: '10px'}}></i>
                                </div>
                                <div className="koh-faq-answer litagsublable">
                              
                                <Link to="/contactus" className="color000 spanlable">Contact us</Link>
                              
                                </div>
                                </div>
                                <div className="koh-faq">
                                <div className="koh-faq-question litagmainlable">
                                <span className="koh-faq-question-span spanlable animate slide-left delay-15"> Resellers (form) </span><i className="fa fa-chevron-right animate slide-left delay-15" aria-hidden="true" style={{fontSize: '25px',marginLeft: '10px'}}></i>
                                </div>
                                <div className="koh-faq-answer litagsublable">
                                Resellers (form)
                                </div>
                                </div>
                                <div className="koh-faq">
                                <div className="koh-faq-question litagmainlable">
                                <span className="koh-faq-question-span spanlable animate slide-left delay-16"> Product </span><i className="fa fa-chevron-right animate slide-left delay-6" aria-hidden="true" style={{fontSize: '25px',marginLeft: '10px'}}></i>
                                </div>
                                <div className="koh-faq-answer litagsublable">
                                Resellers (form)
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    
                    </div> 
                </div> 
                </div>
               
                </div>
            </div>
            </div>
           

        </>
    )
}